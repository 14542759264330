<template>
  <div>
    <el-card class="box-card" shadow="always">
      <el-table
          :data="honorList"
          :fit="true"
          max-height="800"
          style="width: 100%">
        <el-table-column
            type="index"
            align="center"
            label="序号">
        </el-table-column>
        <el-table-column
            align="center"
            prop="launchDate"
            label="日期">
        </el-table-column>
        <el-table-column
            align="center"
            prop="title"
            label="标题">
          <template slot-scope="scope">
            <span>{{scope.row.title}}</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="title"
            label="内容">
          <template slot-scope="scope">
            <span>{{scope.row.content}}</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="weight"
            label="权重">
        </el-table-column>
        <el-table-column
            label="配图"
            align="center">
          <template slot-scope="scope">
            <img class="table-img" :src="scope.row.img">
          </template>
        </el-table-column>
        <el-table-column
            prop="type"
            align="center"
            label="操作">
          <template slot="header" slot-scope="scope">
            <el-button size="medium" type="primary" @click="addHonor">新增</el-button>
          </template>
          <template slot-scope="scope">
            <el-link :underline="false" type="danger" @click="deleNews(scope.row.id)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
        title="新增"
        :visible.sync="addDialogVisible"
        width="50%">
      <el-form ref="form" :model="addForm" label-width="120px">
        <el-form-item label="日期">
          <el-date-picker
              v-model="addForm.date"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="标题">
          <el-input type="text" v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input type="textarea"  v-model="addForm.desc" :autosize="{ minRows: 6, maxRows: 20}"></el-input>
        </el-form-item>
        <el-form-item label="权重">
          <el-input type="number" v-model="addForm.weight"></el-input>
        </el-form-item>
        <el-form-item label="配图">
          <img-upload :url="addForm.url" @change="urlChange"></img-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button @click="addNews">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const moment = require('moment');
import ImgUpload from "@/components/ImgUpload";
import {getNews, addNews, delNews} from "@/plugins/api/news";
export default {
  name: 'News',
  components: {ImgUpload},
  data() {
    return {
      honorList: [
      ],
      previewInfo: {},
      dialogVisible: false,
      addDialogVisible: false,
      addForm: {
        url: '',
        date: '',
        title: '',
        weight: 10,
        desc: '',
      },
      fileList: []
    }
  },
  methods: {
    preview(row) {
      this.previewInfo = row;
      this.dialogVisible = true;
    },
    urlChange(url) {
      this.addForm.url=url
    },
    addHonor() {
      this.addDialogVisible = true
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    async deleNews(id) {
      await delNews({id})
      this.getNews()
    },
    async getNews() {
      this.honorList = await getNews();
    },
    async addNews() {
      await addNews({
        img: this.addForm.url,
        title: this.addForm.title,
        content: this.addForm.desc,
        weight: this.addForm.weight,
        launch_date: moment(this.addForm.date).format('YYYY-MM-DD'),
      })
      this.getNews();
      this.addDialogVisible = false;
      this.addForm = {
        url: '',
        date: '',
        title: '',
        weight: 10,
        desc: '',
      };
    },
  },
  created() {
    this.getNews();
  }
}
</script>

<style scoped>
.table-img {
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 200px;
}
.preview-img {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
}
</style>
