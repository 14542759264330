export function getNews(opt) {
    return axios.post('/news/item/get/', opt);
}

export function addNews(opt) {
    return axios.post('/news/item/insert/', opt);
}

export function delNews(opt) {
    return axios.post('/news/item/delete/', opt);
}
